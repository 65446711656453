import React from "react";

const CircledEnvelop = () => (
  <svg width="40" height="42" viewBox="0 0 40 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_5316_77363)">
      <rect width="40" height="40" rx="20" fill="#001450"/>
      <rect x="11.1113" y="12" width="17.7778" height="16" rx="1.77778" stroke="white" strokeWidth="1.77778"/>
      <path d="M11.1113 14.6701L20.0002 21.7813L28.8891 13.7812" stroke="white" strokeWidth="1.77778"/>
    </g>
    <defs>
      <filter id="filter0_d_5316_77363" x="0" y="0" width="40" height="42" filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                       result="hardAlpha"/>
        <feOffset dy="2"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.043 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5316_77363"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5316_77363" result="shape"/>
      </filter>
    </defs>
  </svg>

);

export default CircledEnvelop;
