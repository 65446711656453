import React from "react";

const CheckmarkRounded = () => (
  <svg
    width="13"
    height="11"
    viewBox="0 0 13 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.6357 0.228098C13.062 0.579189 13.123 1.20941 12.7719 1.63574L5.77193 10.1357C5.59268 10.3534 5.32986 10.4853 5.04822 10.4989C4.76657 10.5125 4.49228 10.4065 4.29289 10.2071L0.292893 6.20713C-0.0976311 5.81661 -0.0976311 5.18345 0.292893 4.79292C0.683417 4.4024 1.31658 4.4024 1.70711 4.79292L4.92827 8.01408L11.2281 0.364321C11.5792 -0.0620043 12.2094 -0.122993 12.6357 0.228098Z"
      fill="currentColor"
    />
  </svg>
);

export default CheckmarkRounded;
