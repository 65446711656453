import React from "react";

const CloseNoBackground = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M1 1L13 13L1 1Z" fill="#001450"/>
    <path d="M1 1L13 13" stroke="#001450" strokeWidth="2"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M1 13L7 7L13 1" fill="#001450"/>
    <path d="M1 13L7 7L13 1" stroke="#001450" strokeWidth="2"/>
  </svg>

);

export default CloseNoBackground;
