import React from "react";

const CloseRound = () => (
  <svg
    width="21"
    height="22"
    viewBox="0 0 21 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.5637 7.29358C14.5637 7.19045 14.4794 7.10608 14.3762 7.10608L12.8294 7.11311L10.4997 9.89046L8.17233 7.11545L6.62311 7.10842C6.51998 7.10842 6.43561 7.19045 6.43561 7.29592C6.43561 7.34045 6.45201 7.38264 6.48014 7.4178L9.52936 11.0506L6.48014 14.6811C6.45182 14.7154 6.4361 14.7584 6.43561 14.803C6.43561 14.9061 6.51998 14.9905 6.62311 14.9905L8.17233 14.9834L10.4997 12.2061L12.827 14.9811L14.3739 14.9881C14.477 14.9881 14.5614 14.9061 14.5614 14.8006C14.5614 14.7561 14.545 14.7139 14.5169 14.6787L11.4723 11.0483L14.5215 7.41545C14.5497 7.38264 14.5637 7.33811 14.5637 7.29358Z"
      fill="currentColor"
    />
    <path
      d="M10.5 0.5C4.70156 0.5 0 5.20156 0 11C0 16.7984 4.70156 21.5 10.5 21.5C16.2984 21.5 21 16.7984 21 11C21 5.20156 16.2984 0.5 10.5 0.5ZM10.5 19.7188C5.68594 19.7188 1.78125 15.8141 1.78125 11C1.78125 6.18594 5.68594 2.28125 10.5 2.28125C15.3141 2.28125 19.2188 6.18594 19.2188 11C19.2188 15.8141 15.3141 19.7188 10.5 19.7188Z"
      fill="currentColor"
    />
  </svg>
);

export default CloseRound;
