import React from "react";

const RoundCheckmark = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="10"
    viewBox="0 0 13 10"
    fill="none"
  >
    <path
      d="M5.4053 9.9768L5.38374 9.99992L0 4.22717L1.75305 2.34745L5.40537 6.26367L11.2469 0L13 1.87972L5.42693 10L5.4053 9.9768Z"
      fill="currentColor"
    />
  </svg>
);

export default RoundCheckmark;
