import React from "react";

const CircleAdd = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="20" height="20" rx="10" fill="#2369BD"/>
    <path
      d="M10.5356 3.57227H9.46415C9.36891 3.57227 9.32129 3.61988 9.32129 3.71512V9.32227H4.00028C3.90504 9.32227 3.85742 9.36988 3.85742 9.46512V10.5366C3.85742 10.6318 3.90504 10.6794 4.00028 10.6794H9.32129V16.2866C9.32129 16.3818 9.36891 16.4294 9.46415 16.4294H10.5356C10.6308 16.4294 10.6784 16.3818 10.6784 16.2866V10.6794H16.0003C16.0955 10.6794 16.1431 10.6318 16.1431 10.5366V9.46512C16.1431 9.36988 16.0955 9.32227 16.0003 9.32227H10.6784V3.71512C10.6784 3.61988 10.6308 3.57227 10.5356 3.57227Z"
      fill="white"/>
  </svg>
);

export default CircleAdd;
