import React from "react";

const Clients = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_2667_55237"
      style={{maskType:"luminance"}}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="32"
      height="32"
    >
      <path d="M31.7407 0H0.258789V32H31.7407V0Z" fill="white" />
    </mask>
    <g mask="url(#mask0_2667_55237)"></g>
    <g clipPath="url(#clip0_2667_55237)">
      <path d="M8.73 10.69H4V11.68H8.73V10.69Z" fill="white" />
      <path d="M8.73 19.89H4V20.88H8.73V19.89Z" fill="white" />
      <path d="M8.73 15.56H4V16.55H8.73V15.56Z" fill="white" />
      <path
        d="M23.8897 18.2C23.6397 17.88 23.2897 17.67 22.8997 17.57L19.2997 16.83V16.27C19.6897 16.09 20.0097 15.88 20.2897 15.6C20.9197 14.97 21.2397 14.15 21.2397 13.31V11.91C21.2397 11.03 20.8897 10.34 20.2897 9.71001C19.6597 9.08001 18.8397 8.76001 17.9997 8.76001H17.8597C16.9797 8.76001 16.1697 9.11001 15.5697 9.71001C14.9697 10.31 14.6697 11.13 14.6697 12.01V13.28C14.6697 14.16 15.1397 15.1 15.5697 15.53C15.8497 15.81 16.1997 16.06 16.5597 16.2V16.87L13.0997 17.58C12.7097 17.69 12.3597 17.9 12.1097 18.21C11.8597 18.53 11.7197 18.92 11.7197 19.3V22.19C11.7197 22.3 11.7197 22.37 11.8297 22.44C11.8997 22.51 11.9697 22.55 12.0797 22.55H23.8897C23.9997 22.55 24.0697 22.55 24.1397 22.44C24.2097 22.37 24.2497 22.3 24.2497 22.19V19.3C24.2497 18.91 24.1397 18.52 23.8997 18.21L23.8897 18.2ZM16.1697 15.06C15.4497 14.28 15.4597 13.18 15.4597 12.03C15.4597 11.36 15.7097 10.73 16.1997 10.27C16.6597 9.81001 17.2897 9.53001 17.9597 9.53001H18.0297C18.6997 9.53001 19.3297 9.81001 19.7897 10.27C20.2497 10.73 20.5297 11.31 20.5297 11.91V13.34C20.5297 14.05 20.2597 14.62 19.8197 15.1C19.6097 15.35 19.3297 15.52 19.0097 15.63C18.6897 15.77 18.3797 15.81 18.0597 15.81H17.9197C17.2497 15.81 16.6497 15.56 16.1597 15.07L16.1697 15.06ZM18.5297 16.65V17.28C18.5297 17.62 18.3397 17.78 17.9497 17.78C17.5597 17.78 17.3397 17.58 17.3397 17.29C17.3397 17.11 17.3397 16.66 17.3397 16.66H18.5397L18.5297 16.65ZM23.5397 21.9H21.5997V20.39C21.5997 19.83 20.8597 19.84 20.8597 20.39V21.84H15.0797V20.39C15.0797 19.86 14.2997 19.83 14.2997 20.39V21.84H12.4997V19.3C12.4997 19.05 12.5697 18.84 12.7097 18.63C12.8497 18.45 13.0597 18.31 13.3097 18.24L16.6997 17.53C16.8297 17.92 17.0497 18.07 17.1897 18.18C17.5997 18.48 18.3097 18.48 18.7797 18.13C18.9597 17.99 19.1297 17.78 19.1997 17.57L22.6897 18.31C22.9397 18.38 23.1097 18.49 23.2897 18.7C23.4297 18.88 23.5397 19.12 23.5397 19.37V21.91V21.9Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.63977 4.87H27.0998V27.15H8.63977V24.18H7.75977V27.57C7.75977 27.82 7.96977 27.99 8.17977 27.99H27.5198C27.7698 27.99 27.9398 27.81 27.9398 27.57V4.61V4.5C27.9498 4.2 27.7998 4 27.4498 4H27.1098H27.1698H8.18977C7.93977 4 7.76977 4.21 7.76977 4.42V7.76H8.64977V4.87H8.63977Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_2667_55237">
        <rect
          width="23.95"
          height="24"
          fill="white"
          transform="translate(4 4)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Clients;
