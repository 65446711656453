import React from "react";

const CircledWhatsapp = () => (
  <svg width="40" height="42" viewBox="0 0 40 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_5316_77348)">
      <rect width="40" height="40" rx="20" fill="#25D366"/>
      <rect width="20" height="20" transform="translate(10 10)" fill="#25D366"/>
      <g clipPath="url(#clip0_5316_77348)">
        <path
          d="M20 10C14.475 10 10 14.475 10 20C10 21.875 10.525 23.625 11.425 25.125L10.0917 30L15.0667 28.6917C16.525 29.5167 18.2 30 20 30C25.525 30 30 25.525 30 20C30 14.475 25.525 10 20 10ZM16.575 15.3333C16.7333 15.3333 16.9083 15.3333 17.05 15.3333C17.225 15.3333 17.425 15.35 17.6083 15.7583C17.825 16.25 18.3083 17.475 18.375 17.6C18.4417 17.725 18.4833 17.8667 18.3917 18.0333C18.3083 18.2 18.2667 18.3 18.15 18.45C18.025 18.5917 17.8917 18.775 17.775 18.8833C17.65 19.0083 17.525 19.1417 17.6667 19.3917C17.8083 19.6417 18.3083 20.45 19.0417 21.1083C19.9917 21.95 20.7833 22.2167 21.0333 22.3333C21.2833 22.4583 21.425 22.4417 21.5667 22.275C21.7167 22.1167 22.1833 21.5583 22.35 21.3083C22.5083 21.0583 22.675 21.1 22.9 21.1833C23.125 21.2667 24.35 21.8667 24.5917 21.9917C24.8333 22.1167 25 22.175 25.0667 22.275C25.1333 22.375 25.1333 22.875 24.925 23.45C24.7167 24.025 23.7083 24.5833 23.2583 24.625C22.8 24.6667 22.375 24.8333 20.2833 24.0083C17.7667 23.0167 16.175 20.4333 16.05 20.2667C15.925 20.1083 15.0417 18.925 15.0417 17.7083C15.0417 16.4917 15.6833 15.8917 15.9083 15.6417C16.1333 15.3917 16.4 15.3333 16.5667 15.3333H16.575Z"
          fill="white"/>
      </g>
    </g>
    <defs>
      <filter id="filter0_d_5316_77348" x="0" y="0" width="40" height="42" filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                       result="hardAlpha"/>
        <feOffset dy="2"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.043 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5316_77348"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5316_77348" result="shape"/>
      </filter>
      <clipPath id="clip0_5316_77348">
        <rect width="20" height="20" fill="white" transform="translate(10 10)"/>
      </clipPath>
    </defs>
  </svg>

);

export default CircledWhatsapp;
