import React from "react";

const Close = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="23"
    viewBox="0 0 22 23"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 1L21 21.2643L1 1Z"
      fill="transparent"
    />
    <path d="M1 1L21 21.2643" stroke="currentColor" strokeWidth="2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 21.3545L21 1.03125L1 21.3545Z"
      fill="transparent"
    />
    <path d="M1 21.3545L21 1.03125" stroke="currentColor" strokeWidth="2" />
  </svg>
);

export default Close;
