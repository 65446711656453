import React from "react";

const CircledPhone = () => (
  <svg width="40" height="42" viewBox="0 0 40 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_5316_77355)">
      <rect width="40" height="40" rx="20" fill="#001450"/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M14.6106 10C13.2144 10 11.1835 11.5231 10.6758 12.5386C9.9502 13.9898 10.07 14.56 10.5489 16.0926C11.1835 18.1234 13.0442 21.7232 15.626 24.216C19.307 27.77 22.734 29.2931 24.2572 29.8008C25.7803 30.3085 27.4304 29.8008 28.4458 28.7854C29.4612 27.77 30.4767 26.7545 29.3343 25.2314C28.5248 24.1521 27.3456 22.9043 25.9072 22.1851C24.5994 21.5312 23.8199 21.7692 23.3687 22.6928C23.1192 23.2034 23.0417 24.1814 22.8609 24.7237C22.6329 25.4078 21.7186 25.2314 20.7032 24.7237C19.731 24.2376 17.2761 22.1851 15.2452 19.1388C13.987 17.2515 15.9982 17.2392 17.2761 16.6003C18.2915 16.0926 18.6063 14.9248 17.7838 13.554C16.2607 11.0154 15.753 10 14.6106 10Z"
            stroke="white" strokeWidth="1.11111"/>
    </g>
    <defs>
      <filter id="filter0_d_5316_77355" x="0" y="0" width="40" height="42" filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                       result="hardAlpha"/>
        <feOffset dy="2"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.043 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5316_77355"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5316_77355" result="shape"/>
      </filter>
    </defs>
  </svg>

);

export default CircledPhone;
